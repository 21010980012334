import styled from "styled-components";
import React from "react";
import classnames from "classnames";

import arrowLeft from "../../img/icons/left-arrow.svg";
import descpIcon from "../../img/icons/descp_icon.svg";
import emailIconGray from "../../img/icons/email-icon-gray.svg";
import emailIconWhite from "../../img/icons/email-icon-white.svg";
import expandOut from "../../img/icons/expand_out.svg";
import facebookIcon from "../../img/icons/facebook-icon.svg";
import facebookIconGray from "../../img/icons/facebook-icon-gray.svg";
import facebookIconWhite from "../../img/icons/facebook-icon-white.svg";
import locationIcon from "../../img/icons/location_icon.svg";
import minimizeImage from "../../img/icons/minimize.svg";
import pinterestIcon from "../../img/icons/pinterest-icon.svg";
import pintresticonGray from "../../img/icons/pinterest-icon-gray.svg";
import pintrestIconWhite from "../../img/icons/pinterest-icon-white.svg";
import arrowRight from "../../img/icons/right-arrow.svg";
import socialShare from "../../img/icons/social_share.svg";
import tumblrIcon from "../../img/icons/tumblr-icon.svg";
import tumblrIconGray from "../../img/icons/tumblr-icon-gray.svg";
import tumblrIconWhite from "../../img/icons/tumblr-icon-white.svg";
import twitterIcon from "../../img/icons/twitter-icon.svg";
import twitterIconGray from "../../img/icons/twitter-icon-gray.svg";
import twitterIconWhite from "../../img/icons/twitter-icon-white.svg";
import xOut from "../../img/icons/x-out.svg";

export const Icon = styled.div`
  ${p => {
    let url,
      backgroundPositionX = "center",
      backgroundPositionY = "center";
    switch (p.type) {
      case "arrow-left":
        backgroundPositionX = "left";
        url = arrowLeft;
        break;
      case "description":
        url = descpIcon;
        break;
      case "email":
        url = emailIconGray;
        break;
      case "email-white":
        url = emailIconWhite;
        break;
      case "expand-out":
        url = expandOut;
        break;
      case "facebook":
        url = facebookIcon;
        break;
      case "tumblr":
        url = tumblrIcon;
        break;
      case "twitter":
        url = twitterIcon;
        break;
      case "pinterest":
        url = pinterestIcon;
        break;
      case "location":
        url = locationIcon;
        break;
      case "expand-in":
        url = minimizeImage;
        break;
      case "arrow-right":
        url = arrowRight;
        backgroundPositionX = "right";
        break;
      case "close":
        url = xOut;
        break;
      case "share":
        url = socialShare;
        break;
    }
    return {
      backgroundImage: `url(${url})`,
      backgroundPositionX,
      backgroundPositionY
    };
  }}

  width:32px;
  height: 36px;

  background-repeat: no-repeat;
  background-size: contain;
  &:hover {
    filter: ${p => {
      let filter = "brightness(2)";
      switch (p.type) {
        case "facebook":
        case "tumblr":
        case "pinterest":
        case "twitter":
          filter = `invert(0.2) ${filter}`;
      }
      return filter;
    }};
  }
  cursor: ${p => (p.interactive == false ? null : "pointer")};
`;

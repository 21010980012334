import React from "react";
import _ from "lodash";

// let debugOn = true;
let debugOn = false;

export const Debug = ({ children, force, open }) => {
  if (!debugOn && !force) return null;
  let [isOpen, setOpen] = React.useState(open);
  let style = {
    overflow: "scroll",
    maxHeight: isOpen ? "400px" : "100px"
  };

  let onClick = React.useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);

  let content;
  if (isOpen) {
    content = JSON.stringify(children, null, 2);
  } else {
    if (_.isArray(children)) {
      content = _.map(children, (c, i) => <Debug key={i}>{c}</Debug>);
    } else if (_.isObject(children)) {
      content = Object.keys(children).join(", ");
    } else if (_.isString(children)) {
      content = children;
    }
  }

  return (
    <pre onClick={onClick} style={style}>
      {content}
    </pre>
  );
};

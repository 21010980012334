import styled from "styled-components";

export const LevelLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
`;
LevelLeft.defaultProps = {};
export const LevelRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
`;
LevelRight.defaultProps = {};

export const LevelCenter = styled.div`
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
`;
LevelCenter.defaultProps = {};

export const Level = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > ${LevelCenter} {
    flex-grow: 1;
  }
`;
Level.defaultProps = {};

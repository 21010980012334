import classnames from "classnames";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TumblrShareButton,
  TwitterShareButton
} from "react-share";
import styled from "styled-components";
import { HTMLContent } from "../components/Content";
import { Debug } from "../components/Debug";
import Layout from "../components/Layout";
import { Icon } from "../components/shared/Icon";
import { hero, Image } from "../components/shared/Image";
import {
  Level,
  LevelCenter,
  LevelLeft,
  LevelRight
} from "../components/shared/Level";
import { PageTitle } from "../components/shared/PageTitle";

const LinkOrBlank = ({ to, children }) => {
  if (to) {
    return <Link to={to}>{children}</Link>;
  } else {
    return null;
  }
};

const HoverShow = styled.div``;

const IconHoverTextBoxBody = ({ description, className, type }) => {
  if (!description) {
    return null;
  }

  return (
    <div className={className}>
      <Icon type={type} />
      <HoverShow>{description}</HoverShow>
    </div>
  );
};

let hasLineBreaksRe = /\r|\n/;
const IconHoverTextBox = styled(IconHoverTextBoxBody)`
  ${HoverShow} {
    display: none;
  }
  margin-right: 70px;
  position: relative;
  &:hover ${HoverShow} {
    z-index: 1000;
    background: white;
    display: block;
    border: 1px solid black;
    position: absolute;
    padding: 9px 10px;
    bottom: calc(100% + 25px);
    text-align: center;
    ${p => {
      if (hasLineBreaksRe.exec(p.description)) {
        return {
          whiteSpace: "pre",
          minWidth: "100px"
        };
      } else {
        return {
          width: "300px"
        };
      }
    }}
  }

  &:hover ${HoverShow}:after, &:hover ${HoverShow}:before {
    top: 100%;
    left: 15px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:hover ${HoverShow}:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 12px;
    margin-left: -12px;
  }
  &:hover ${HoverShow}:before {
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 13px;
    margin-left: -13px;
  }
`;

const PieceInfo = styled(({ pieceFrontMatter, parentTitle, className }) => {
  let { title, imageId, dimensions } = pieceFrontMatter;
  let classes = classnames("level has-text-weight-light", className);
  return (
    <Level className={classes}>
      <LevelLeft>{title}</LevelLeft>
      <LevelCenter className="is-hidden-mobile">{dimensions}</LevelCenter>
      <LevelRight>{imageId}</LevelRight>
    </Level>
  );
})`
  width: 100%;
  margin-top: 10px;
  font-size: 11px;
  color: #4a4a4a;
`;
const PieceDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  @media (min-width: 768px) {
    ${p =>
      !p.expanded
        ? {
            padding: "44px 60px 70px"
          }
        : null};
  }

  ${Image} img {
    max-height: ${p => (!p.expanded ? "60vh" : "100vh")};
    max-width: ${p => (!p.expanded ? "75vw" : "100vw")};
    width: auto;
  }
  ${Image} {
    min-height: ${p => (p.expanded ? "100vh" : null)};
  }

  user-select: none;
  ${PieceInfo} {
    user-select: text;
  }
`;
const ImageArea = styled(LevelCenter)`
  flex-direction: column;
  flex-grow: 0;
  max-width: 90%;

  ${Level} {
    align-items: flex-start;
  }

  ${Image} {
    max-width: 90%;
  }
`;

const ExpandIcon = styled.span`
  background: white;
  ${p => {
    if (p.expanded) {
      return {
        position: "fixed",
        top: 0,
        left: 0,
        background: "white"
      };
    }
  }}
  ${Icon} {
    margin-top: 15px;
    margin-left: 15px;

    ${p => {
      if (p.expanded) {
        return {
          width: "40px",
          height: "40px"
        };
      }
    }}
  }
`;
const IconActions = styled(LevelLeft)`
  ${Icon} {
    margin-right: 15px;
    margin-bottom: 15px;
  }
`;
const ImageRow = styled(Level)`
  
  &>${LevelLeft},&>${LevelRight}{
    max-width: 12vw;
  }
  /* min-height:75vh; */
`;
const TopRow = styled(Level)`
  /* min-height: 12vh; */
`;
const BottomRow = styled(Level)`
  /* min-height: 12vh; */
  align-items: flex-end;
`;

const ShareButton = styled.div``;

const ShareMenuBody = ({ location, media, title, className, close }) => {
  let url = location.href;

  let backgroundRef = React.createRef();
  return (
    <>
      <ModalBackground
        onClick={e => {
          if (e.target == backgroundRef.current) {
            close();
          }
        }}
        ref={backgroundRef}
      >
        <div className={className}>
          <PageTitle>Share</PageTitle>
          <ShareRow>
            <ShareButton>
              <FacebookShareButton url={url} quote={title}>
                <Icon type="facebook" />
              </FacebookShareButton>
            </ShareButton>
            <ShareButton>
              <TwitterShareButton url={url} title={title}>
                <Icon type="twitter" />
              </TwitterShareButton>
            </ShareButton>
            <ShareButton>
              <PinterestShareButton url={url} media={media} description={title}>
                <Icon type="pinterest" />
              </PinterestShareButton>
            </ShareButton>
            <ShareButton>
              <TumblrShareButton url={url} title={title}>
                <Icon type="tumblr" />
              </TumblrShareButton>
            </ShareButton>
            <ShareButton>
              <EmailShareButton url={url} openWindow subject={title}>
                <Icon type="email" />
              </EmailShareButton>
            </ShareButton>
          </ShareRow>
        </div>
      </ModalBackground>
    </>
  );
};

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ShareMenu = styled(ShareMenuBody)`
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  padding: 25px 25px 50px;
  background: white;
  z-index: 199;
  max-height: 200px;
  ${PageTitle} {
    margin-top: 0;
  }
`;

const ShareRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const BlogPostTemplate = ({
  location: pageLocation,
  parentNode,
  nextNode,
  previousNode,
  helmet,
  node
}) => {
  let [expanded, setExpanded] = React.useState(false);
  let [sharing, setSharing] = React.useState(false);

  let { image, title, description, location } = node.frontmatter;

  let content;
  if (expanded) {
    content = (
      <>
        <IconActions>
          <ExpandIcon
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <Icon type="expand-in" />
          </ExpandIcon>
        </IconActions>
        <div style={{ touchAction: "auto" }}>
          <Image field={image} />
        </div>
      </>
    );
  } else {
    let descriptionLocation = [];
    if (location) {
      descriptionLocation.push(
        <IconHoverTextBox
          key="location"
          type="location"
          description={location}
        />
      );
    }
    if (description) {
      descriptionLocation.push(
        <IconHoverTextBox
          key="description"
          type="description"
          description={description}
        />
      );
    }
    content = (
      <>
        <TopRow>
          <IconActions>
            <ExpandIcon
              expanded={expanded}
              onClick={() => setExpanded(!expanded)}
            >
              <Icon type="expand-out" />
            </ExpandIcon>{" "}
            <Icon type="share" onClick={() => setSharing(!sharing)} />
          </IconActions>
          <LevelRight>
            {parentNode ? (
              <Link to={parentNode.fields.slug}>
                <Icon type="close" />
              </Link>
            ) : null}
          </LevelRight>
        </TopRow>
        <ImageRow>
          <LevelLeft>
            <LinkOrBlank to={previousNode && previousNode.fields.slug}>
              <Icon type="arrow-left" />
            </LinkOrBlank>
          </LevelLeft>
          <ImageArea>
            <Image field={image} transforms={hero}>
              <PieceInfo
                pieceFrontMatter={node.frontmatter}
                parentTitle={parentNode && parentNode.frontmatter.title}
              />
            </Image>
          </ImageArea>
          <LevelRight>
            <LinkOrBlank to={nextNode && nextNode.fields.slug}>
              <Icon type="arrow-right" />
            </LinkOrBlank>
          </LevelRight>
        </ImageRow>
        <BottomRow>
          <LevelLeft>{descriptionLocation}</LevelLeft>
        </BottomRow>
        {sharing ? (
          <ShareMenu
            title={title}
            location={pageLocation}
            media={image.publicURL}
            close={() => setSharing(false)}
          />
        ) : null}
      </>
    );
  }

  let zoomable;
  if (expanded) {
    zoomable = (
      <Helmet>
        <meta
          name="viewport"
          content="user-scalable=yes, target-densitydpi=device-dpi"
        ></meta>
      </Helmet>
    );
  }

  return (
    <section>
      {helmet || ""}
      {zoomable}
      <Debug>{node}</Debug>
      <Debug>{{ parentNode }}</Debug>
      <PieceDetail expanded={expanded}>{content}</PieceDetail>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  tag_line: PropTypes.string,
  helmet: PropTypes.object
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout fullScreen>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Frank Masi">
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
        title={post.frontmatter.title}
        parent={post.frontmatter.parent}
        parentNode={post.fields.parentNode}
        previousNode={post.fields.previousNode}
        nextNode={post.fields.nextNode}
        node={post}
        location={location}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BlogPost;

export const pageQuery = graphql`
  query PieceDetail($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        parentNode {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }

        nextNode {
          id
          fields {
            slug
          }
        }
        previousNode {
          id
          fields {
            slug
          }
        }
      }

      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        parent
        description
        location
        dimensions
        imageId

        image {
          publicURL
        }
        # {
        #   childImageSharp {
        #     fluid(maxWidth: 2048, quality: 100) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        # }
      }
    }
  }
`;

import React from "react";
import classnames from "classnames";
import styled from "styled-components";
import queryString from "querystring";

const Img = styled.img`
  display: block !important;
  height: auto;
  width: auto;
  max-height: 100%;
`;

export const Figure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const thumbnailSmall = {
  nf_resize: "fit",
  h: 320
};
export const hero = {
  nf_resize: "fit",
  h: 1000
};

const ImageRaw = ({ field, className, children, transforms }) => {
  if (!field) {
    return null;
  }
  className = classnames("", className);
  let src;
  if (field.childImageSharp) {
    src = field.childImageSharp.fluid.src;
  } else if (field.publicURL) {
    src = field.publicURL;
    if (transforms) {
      src = `${src}?${queryString.stringify(transforms)}`;
    }
  } else {
    src = field;
  }

  return (
    <Figure className={className}>
      <Img src={src} />
      {children}
    </Figure>
  );
};

export const Image = styled(ImageRaw)``;
